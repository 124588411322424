import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import data_country from "../../public/data_country";
import { Typography, InputBase, Divider, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const PhoneCodePicker = (props) => {
  const classes = useStyles(props);
  const { handleSelectPhoneCode, t, onClose } = props;
  const [search, setSearch] = useState("");
  const [filteredSearch, setFilteredSearch] = useState(data_country);

  useEffect(() => {
    setFilteredSearch(
      data_country.filter((val) =>
        val.name
          .toLocaleLowerCase()
          .match(new RegExp(search.toLocaleLowerCase()))
      )
    );
  }, [search]);

  return (
    <Box className={classes.phoneCodePickerWrapper}>
      <Box className={classes.searchWrapper}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="16px"
        >
          <Typography className={classes.title}>Select country code</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </Box>
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
          <InputBase
            placeholder={t("look-for-the-name-or-country-code")}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            fullWidth
            value={search}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search.length !== 0 && (
            <Box className={classes.deleteIcon}>
              <CloseIcon onClick={() => setSearch("")} style={{ height: 16 }} />
            </Box>
          )}
        </Box>
      </Box>
      <Divider style={{ paddingTop: 87, backgroundColor: "white" }} />

      <Box className={classes.listCountry}>
        {filteredSearch.map((res, index) => (
          <React.Fragment key={index}>
            <Box
              className={classes.between}
              style={{ padding: 16, cursor: "pointer" }}
              onClick={() => handleSelectPhoneCode(res)}
            >
              <Box className={classes.alignItemsCenter}>
                <img
                  src={`/img/flags/${res.country_code.toLocaleLowerCase()}.png`}
                  height={20}
                  width={30}
                  alt=""
                />
                <Typography style={{ fontWeight: 600, paddingLeft: 20 }}>
                  {res.name}
                </Typography>
              </Box>
              <Typography>{res.dial_code}</Typography>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default PhoneCodePicker;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 18,
      fontWeight: 700,
    },
    phoneCodePickerWrapper: {
      width: "100%",
      margin: "auto",
      height: "100vh",
    },
    search: {
      borderRadius: 16,
      marginLeft: 0,
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #929292",
    },
    searchWrapper: {
      padding: 24,
      backgroundColor: "#ffffff",
      width: "100%",
      maxWidth: 444,
      position: "fixed",
      zIndex: 1,
      borderRadius: "4px 4px 0px 0px",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#a0a4a8",
    },
    deleteIcon: {
      color: "#a0a4a8",
      marginRight: 10,
      cursor: "pointer",
      display: "flex",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: theme.spacing(1.8, 1.8, 1.8, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px) !important`,
      transition: theme.transitions.create("width"),
      width: "100% !important",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      fontSize: 14,
    },
    between: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    alignItemsCenter: {
      display: "flex",
      alignItems: "center",
    },
    closeIcon: {
      cursor: "pointer",
    },
    listCountry: {
      backgroundColor: "#ffffff",
      marginTop: 16,
      padding: 24,
    },
  })
);
